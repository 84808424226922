<template>
  <transition appear>
    <div class="bg-white w-11.5/12 rounded-xl my-5 mx-auto py-1 px-5 shadow-lg">
      <div class="flex justify-between items-center">
        <div class="mt-5 flex justify-start items-center cursor-pointer">
          <span
            @click="goBack"
            class="material-icons rounded-full bg-teal text-white"
          >
            chevron_left
          </span>
          <router-link
            class="font-bold text-teal mx-2"
            :to="{ name: `us-locums` }"
          >
            Back To Dashboard
          </router-link>
        </div>
        <div>
          <select
            @change="onLocationFilter"
            class="bg-lightGrey w-80 py-1 px-2 text-grey"
          >
            <option :value="reset">Select Location</option>
            <option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
            >
              {{
                location.practice_name[0].toUpperCase() +
                location.practice_name.slice(1)
              }}
            </option>
          </select>
        </div>
      </div>

      <div class="bg-lightGrey rounded-xl w-12/12 my-5 mx-auto py-7 px-5">
        <div class="flex justify-between items-center">
          <h2 class="text-grey font-bold text-2xl">
            {{ monthString }} Sessions
          </h2>
          <div class="flex gap-x-2">
            <va-button
              @click="decrementMonth"
              class="w-56"
              icon="chevron_left"
              color="#00b5b5"
              text-color="white"
            >
              Previous Month
            </va-button>
            <va-button
              @click="incrementMonth"
              class="w-56"
              icon-right="chevron_right"
              color="#00b5b5"
              text-color="white"
            >
              Next Month
            </va-button>
          </div>
        </div>
        <div class="bg-lightGrey rounded-xl min-h-cardM mt-5">
          <div class="mt-5">
            <div class="flex gap-x-2 text-sm">
              <span
                @click="allSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'all' }"
              >
                All
              </span>
              <span
                @click="filledSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'filled' }"
              >
                Filled
              </span>
            </div>
            <div
              :key="refreshTable"
              class="border-2 border-grey w-full px-x py-7 bg-white"
            >
              <!-- <div class="flex justify-center items-center h-64">
                        <span
                            class="material-icons flex align-center text-8xl text-grey"
                        >
                            event_busy
                        </span>
                        </div> -->
              <!-- <div
                class="w-11.5/12 my-3 rounded-xl mx-auto bg-lightGrey py-7 px-5 flex"
              >
                <div class="date-container w-full md:w-1/5">
                  <h2 class="font-bold text-grey text-xl my-5">
                    Tuesday 1st March
                  </h2>
                  <p class="font-bold text-teal my-5 mt-10">No Sessions</p>
                </div>
                <div class="w-full flex-wrap md:w-3/5 flex items-center gap-5">
                  <div class="flex my-auto items-center">
                    <span
                      class="material-icons text-teal border-2 text-center h-11 w-11 border-teal text-4xl rounded-full cursor-pointer"
                    >
                      add
                    </span>
                    <h2 class="text-grey font-bold text-xl ml-3">
                      Add Session
                    </h2>
                  </div>
                </div>
              </div> -->
              <!-- <div class="flex justify-center items-center h-64">
                        <span
                            class="material-icons flex align-center text-8xl text-grey"
                        >
                            event_busy
                        </span>
                        </div> -->
              <div
                v-for="day in daysInMonth"
                :key="day.dayNumber"
                class="
                  w-11.5/12
                  my-3
                  rounded-xl
                  mx-auto
                  bg-lightGrey
                  py-7
                  px-5
                  flex
                "
              >
                <div class="date-container w-full md:w-1/5">
                  <h2 class="font-bold text-grey text-xl my-5">
                    {{ day.dayString }}
                  </h2>
                  <p
                    class="font-bold text-teal my-5 mt-10"
                    v-if="day.sessions.length === 0"
                  >
                    No Sessions
                  </p>
                </div>
                <div
                  v-if="day.sessions?.length > 0"
                  class="w-full flex-wrap md:w-3/5 flex items-center gap-5"
                >
                  <div
                    v-for="session in day.sessions"
                    :key="session.id"
                    class="
                      session-card
                      border-2
                      flex flex-col
                      border-grey
                      rounded-xl
                      py-4
                      px-6
                    "
                  >
                    <div class="font-bold flex session-row">
                      <div>
                        <span class="text-grey"
                          >{{ format12(session.start_time) }} -
                          {{ session.end_date }}({{
                            format12(session.end_time)
                          }})</span
                        >
                      </div>
                    </div>
                    
                    <div class="font-bold flex session-row">
                      <div class="flex gap-x-2 w-full">
                        <span class="text-teal"> Name: </span>
                        <span class="text-grey"> {{ session.name }} </span>
                      </div>
                    </div>
                    <div class="font-bold flex session-row">
                      <div class="flex gap-x-2 w-1/2">
                        <span class="text-teal"> Position: </span>
                        <span v-if="session.locums[0]" class="text-grey">
                          {{ session.locums[0]?.roles[0].name }}
                        </span>
                        <span v-else class="text-grey"> Unavailable </span>
                      </div>
                    </div>
                    <div class="font-bold flex session-row">
                      <div class="flex gap-x-2 w-1/2">
                        <span class="text-teal"> Location: </span>
                        <span class="text-grey"> {{ session.location }} </span>
                      </div>
                    </div>
                    <div class="font-bold flex session-row">
                      <div class="flex gap-x-2 w-1/2">
                        <span class="text-teal"> Rate: </span>
                        <span class="text-grey">
                          ${{ session.rate }}/{{ session.unit }}
                        </span>
                      </div>
                      <div class="flex gap-x-2 w-1/2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "locumSessions",
  components: {},
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      monthToShow: new Date(),
      monthString: moment(new Date()).format("MMMM YYYY"),
      filter: "all",
      daysInMonth: [],
      allSessions: [],
      vacantSessions: [],
      refreshTable: 0,

      validate: true,
      createSessionPayload: {
        practice: 1,
        role: null,
        name: "GP Session",
        quantity: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        rate: null,
        unit: null,
        location: null,
      },
      masks: {
        weekdays: "WWW",
      },

      monthlySessions: [],
      locations: [],
      tableState: "all",
    };
  },
  validations() {
    return {
      createSessionPayload: {
        role: {
          required: helpers.withMessage("Role field is required", required),
        },
        quantity: {
          required: helpers.withMessage("Quantity field is required", required),
        },
        start_date: {
          required: helpers.withMessage(
            "Start-date field is required",
            required
          ),
        },
        end_date: {
          required: helpers.withMessage("End-date field is required", required),
        },
        start_time: {
          required: helpers.withMessage(
            "Start-time field is required",
            required
          ),
        },
        end_time: {
          required: helpers.withMessage("End-time field is required", required),
        },
        rate: {
          required: helpers.withMessage("Wage field is required", required),
        },
        unit: {
          required: helpers.withMessage("Unit field is required", required),
        },
        location: {
          required: helpers.withMessage("Location field is required", required),
        },
      },
    };
  },
  async created() {
    await this.fetchMonthlySessions();
    this.getMonthlySessions();
    await this.fetchLocations();

    this.getDaysInMonth();
    this.getLocations();
  },
  methods: {
    async incrementMonth() {
      this.tableState = "all";
      this.monthToShow = new Date(
        this.monthToShow.getFullYear(),
        this.monthToShow.getMonth() + 1,
        1
      );
      this.getDaysInMonth();
      this.monthString = moment(this.monthToShow).format("MMMM YYYY");

      if (Number(this.month) + 1 == 13) {
        this.month = 1;
        this.year = Number(this.year) + 1;
      } else {
        this.month = Number(this.month) + 1;
      }
      if (this.month / 10 < 1) {
        this.month = "0" + this.month;
      }
      await this.fetchMonthlySessions();
      this.getMonthlySessions();
      this.getDaysInMonth();
    },
    async decrementMonth() {
      this.tableState = "all";
      this.monthToShow = new Date(
        this.monthToShow.getFullYear(),
        this.monthToShow.getMonth() - 1,
        1
      );
      this.getDaysInMonth();
      this.monthString = moment(this.monthToShow).format("MMMM YYYY");

      if (Number(this.month) - 1 == 0) {
        this.month = 12;
        this.year = Number(this.year) - 1;
      } else {
        this.month = Number(this.month) - 1;
      }
      if (this.month / 10 < 1) {
        this.month = "0" + this.month;
      }
      await this.fetchMonthlySessions();
      this.getMonthlySessions();
      this.getDaysInMonth();
    },
    format12(time) {
      let newTime;
      let hours = time.slice(0, 2);
      let minutes = time.slice(3, 5);
      if (hours > 12) {
        newTime = hours % 12;
        newTime += `:${minutes} pm`;
      } else {
        newTime = `${hours}:${minutes} am`;
      }
      return newTime;
    },
    async fetchMonthlySessions() {
      try {
        if (!this.year || !this.month) {
          const date = new Date().toISOString().split("-");
          this.year = date[0];
          this.month = date[1];
        }
        await this.$store.dispatch("UserLocums/fetchMonthlySessions", {
          date: `${this.year}-${this.month}`,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getMonthlySessions() {
      this.monthlySessions = [
        ...this.$store.getters["UserLocums/getMonthlySessions"],
      ];
      return this.$store.getters["UserLocums/getMonthlySessions"];
    },
    async createSession() {
      this.validate = true;
      const validated = await this.v$.$validate();
      if (validated === true) {
        await this.$store.dispatch(
          "ReLocums/createSession",
          this.createSessionPayload
        );
        this.createSessionPayload = {
          practice: 1,
          role: null,
          name: "GP Session",
          quantity: null,
          start_date: null,
          end_date: null,
          start_time: null,
          end_time: null,
          rate: null,
          unit: null,
          location: null,
        };
        this.validate = false;
      }

      /////

      await this.fetchMonthlySessions();
      this.getMonthlySessions();

      this.getDaysInMonth();

      /////

      setTimeout(() => {
        this.validate = false;
      }, 5000);
    },
    getDaysInMonth() {
      var daysInMonth = moment(this.monthToShow).daysInMonth();
      var arrDays = [];

      while (daysInMonth) {
        let dayString =
          moment(this.monthToShow).date(daysInMonth).format("dddd") +
          " " +
          moment(this.monthToShow).date(daysInMonth).format("Do") +
          " " +
          moment(this.monthToShow).date(daysInMonth).format("MMMM");
        let dayNumber = moment(this.monthToShow).date(daysInMonth).format("D");
        let sessions = this.monthlySessions.filter((session) => {
          return Number(session.start_date.split("-")[2]) == Number(dayNumber);
        });
        arrDays.push({ dayString, dayNumber, sessions });
        daysInMonth--;
      }
      this.daysInMonth = arrDays;
      this.daysInMonth.sort((a, b) => a.dayNumber - b.dayNumber);

      this.allSessions = this.daysInMonth;

      this.vacantSessions = this.daysInMonth;
      this.vacantSessions = this.daysInMonth.filter(
        (session) => session.sessions[0]?.locums_count < 1
      );

      this.filledSessions = this.daysInMonth;
      this.filledSessions = this.daysInMonth.filter(
        (session) => session.sessions[0]?.locums_count > 0
      );
      return arrDays;
    },

    async fetchLocations() {
      try {
        await this.$store.dispatch("ReLocums/fetchLocations");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    getLocations() {
      this.locations = this.$store.getters["ReLocums/getLocations"];
      return this.locations;
    },
    onLocationFilter(event) {
      this.fetchMonthlyFilterSessions(event.target.value);
    },

    async fetchMonthlyFilterSessions(data) {
      try {
        await this.$store.dispatch("UserLocums/fetchMonthlyFilterSessions", {
          practice: data,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.getMonthlyFilterSessions();
    },

    getMonthlyFilterSessions() {
      this.monthlySessions = [
        ...this.$store.getters["UserLocums/getMonthlyFilterSessions"],
      ];
      this.getDaysInMonth();
      return this.$store.getters["UserLocums/getMonthlyFilterSessions"];
    },

    goBack() {
      this.$router.push({ name: "us-locums" });
    },

    allSessionsFunc() {
      this.tableState = "all";
      this.daysInMonth = this.allSessions;
      this.refreshTable++;
    },

    vacantSessionsFunc() {
      this.tableState = "vacant";
      this.daysInMonth = this.vacantSessions;
      this.refreshTable++;
    },

    filledSessionsFunc() {
      this.tableState = "filled";
      this.daysInMonth = this.filledSessions;
      this.refreshTable++;
    },

    monthFormat(value) {
      if (value < 10) {
        return "0" + value;
      } else {
        return value;
      }
    },
  },
};
</script>

<style scoped>
.filterDropDown .vs__dropdown-toggle,
.filterDropDown .vs__dropdown-menu {
  border-radius: 0.5rem;
  background: lightgray;
  padding-left: 0.2rem;
}
.filterDropDown .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
#filterDropDown .filterDropDown .vs__clear,
#filterDropDown .filterDropDown .vs__open-indicator {
  fill: black;
}
.filterDropDown {
  min-width: 300px;
  border: none;
  background: lightgray;
}
.session-row {
  min-width: 400px;
  max-width: 400px;
}

.buttonActive {
  border-color: teal;
  background-color: teal;
  color: white;
  padding-top: 2px;
}
</style>
